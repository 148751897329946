.demo-image__preview[data-v-78c7cb96] {
  text-align: center;
}
.switchover[data-v-78c7cb96] {
  width: 20px;
  text-align: center;
}
.switchover .two_btn[data-v-78c7cb96] {
  font-size: 30px;
  color: #362721;
  line-height: 60px;
  background: #eee;
  cursor: pointer;
}
.switchover .arrow_left[data-v-78c7cb96] {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.switchover .arrow_right[data-v-78c7cb96] {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
