.dialog-footer[data-v-6ae8f45c] {
  text-align: right;
  margin-top: 10px;
}
.item_icon[data-v-6ae8f45c] {
  display: inline-block;
  cursor: pointer;
}
.item_icon i[data-v-6ae8f45c] {
  display: inline-block;
  line-height: 13px;
  font-size: 13px;
  margin-right: 4px;
}
.item_icon .iconfont icon-cuowu[data-v-6ae8f45c] {
  color: #f56c6c;
}
.item_icon .iconfont icon-duihao[data-v-6ae8f45c] {
  color: #67c23a;
}
.item_icon .el-icon-circle-check[data-v-6ae8f45c] {
  color: #67c23a;
}
.item_icon .iconfont icon-info[data-v-6ae8f45c] {
  color: #409eff;
}
.item_icon .iconfont icon-cuowu[data-v-6ae8f45c] {
  color: #e6a23c;
}
.item_icon .iconfont icon-cuowu-outline[data-v-6ae8f45c] {
  color: #e6a23c;
}
.item_icon p[data-v-6ae8f45c] {
  display: inline-block;
  font-size: 14px;
  line-height: 28px;
  color: #333;
  margin-left: 4px;
}